.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}



.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.topbar{
  width: 100%;
  background: linear-gradient(181deg, rgb(65 78 189) 
  50%, rgba(0,18,142, 1)100%);
  padding: 5px 0px;
  margin: 0 auto ;
  height:70px;
}


.phising {
  right: 0;
  left: 0;
  margin:5px auto;
  height: 3rem !important;
  
}

.color {
  font-size: 15px;
  position: absolute;
  color: #fff;
  right: 63px;
  cursor: pointer;
  top: 20px;
}



.buttons {
  margin: 10%;
  text-align: center;
}

.btn-hover {
  width: 183px;
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  cursor: pointer;
  /* margin: 20px; */
  height: 44px;
  text-align: center;
  border: none;
  background-size: 300% 100%;
  border-radius: 50px;
  moz-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
}

.btn-hover:hover {
  background-position: 100% 0;
  moz-transition: all .4s ease-in-out;
  -o-transition: all .4s ease-in-out;
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
}

.btn-hover:focus {
  outline: none;
}

.btn-hover.color-6 {
  background-image: linear-gradient(to right, #00deef , #0062ff, #00A8C5, #D9E021);
  box-shadow: 0 4px 15px 0 rgba(83, 176, 57, 0.75);
}

.addressSec_add span {
  font-size: 18px;
  line-height: 2;
  word-wrap: break-word;
  color:#fff !important;
}

a {
  color: #00c8f2;
  text-decoration: underline;
}

@media (min-width:300px) and (max-width:768px) {

  .navbar-light .navbar-toggler-icon {
    background-image:url(../src/assets/img/menu.png);
    background-repeat: no-repeat;
}

}